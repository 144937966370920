import { EnumTransactionStatuses, Transaction } from 'api/endpoints/transaction'
import { useExternalTransactions } from 'api/hooksApi/useExternalTransactions'
import React, {
  PropsWithChildren,
  useMemo,
  useState,
} from 'react'
import { useParams } from 'react-router-dom'
import { initialTransactionApiParams } from '../constants'
import { TransactionApiParams } from '../types'

interface ContextProps {
  isFetchingTransactions: boolean;
  pendingTransactions: Transaction[];
  resentTransactions: Transaction[];
  transactionApiParams: TransactionApiParams;
  searchTransactionByName: string;
  setSearchTransactionByName: (search: string) => void;
  setTransactionApiParams:(value: TransactionApiParams) => void
}

const Context = React.createContext<ContextProps | undefined>(undefined)

const filterByNameTransaction = (transactions: Transaction[], search: string) => {
  if (!search) {
   return transactions;
  }

  return transactions.filter((item) =>
    item.name.toLowerCase().includes(search.toLowerCase())
  );
};

export const ExternalAccountProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const { bankId } = useParams()
  const [transactionApiParams, setTransactionApiParams] = useState<TransactionApiParams>(
    initialTransactionApiParams
  )
  const [searchTransactionByName, setSearchTransactionByName] = useState<string>('');
  const { isLoading: isFetchingTransactions, transactions } = useExternalTransactions({
    financialAccountId: bankId ?? '',
    dateFrom: transactionApiParams.dateFrom.format('YYYY-MM-DD HH:mm:ss'),
    dateTo: transactionApiParams.dateTo.format('YYYY-MM-DD HH:mm:ss'),
  });
  const filteredTransactions = useMemo(() => {
    return filterByNameTransaction(transactions || [], searchTransactionByName);
  }, [transactions, searchTransactionByName]);

  const pendingTransactions = filteredTransactions.filter(
    (t) => t.status === EnumTransactionStatuses.PENDING
  );

  const resentTransactions = filteredTransactions.filter(
    (t) => t.status !== EnumTransactionStatuses.PENDING
  );

  return <Context.Provider value={{
    isFetchingTransactions,
    searchTransactionByName,
    setSearchTransactionByName,
    pendingTransactions,
    resentTransactions,
    transactionApiParams,
    setTransactionApiParams,
  }}>{children}</Context.Provider>
}

export const useTransactions = () => React.useContext(Context) as ContextProps
