import CurrencyDollar from "assets/svg/dollar-blue.svg";
import { Skeleton, Typography } from "@mui/material";
import { useExternalAccountBalanceQuery } from "api/endpoints/financialAccounts";
import ItemBalance from "../itemBalance";

import styles from '../../styles.module.scss'
import { useExternalAccounts } from "api/hooksApi/useExternalAccounts";
import helper from "services/helper";

const availableBalanceToolTip =
  "The amount of money in your account that you can use right now.";

export const OtherBanksTab = () => {
  const { availableBalance, isLoading } = useExternalAccounts();

  const renderBalance = () => {
    if (isLoading) {
      return <Skeleton width="100%" height="100px" />
    }

    if (availableBalance) {
      return (
        <ItemBalance
          balance={helper.moneyFormat(availableBalance) || ''}
          description={"Available Balance"}
          tooltip={availableBalanceToolTip}
        />
      )
    }
  }

  return (
    <>
      <div className={styles.header}>
        <img src={CurrencyDollar} />
        <Typography fontSize={14}>
          Summary of your external bank accounts and balances.
        </Typography>
      </div>
      <div className={styles.box}>
        {renderBalance()}
      </div>
    </>
  )
}