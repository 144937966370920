import { Skeleton } from 'components/skeleton';
import { TransitionAnimation } from 'shared/ui/animations';
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableFooter,
  useMediaQuery,
  Divider,
} from '@mui/material';
import { Typography } from 'shared/ui/typography';
import { Button } from 'shared/ui/buttons';
import suitcaseIcon from 'assets/svg/suitcase-blue.svg';
import styles from './styles.module.scss';
import helper from 'services/helper';
import { useNavigate } from 'react-router-dom';
import { appRoutes } from 'routes';
import { FinancialAccount } from 'api/endpoints/financialAccounts';
import { formatAccountName } from 'shared/lib/format';
import { useBoolean } from 'shared/hooks/useBoolean';
import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined';
import ArrowDropUpOutlinedIcon from '@mui/icons-material/ArrowDropUpOutlined';
import { useHomeLists } from '../provider';
import { EmptyTable } from '../emptyTable';
import { BankIcon } from 'shared/ui/icons';

export const AccountsTable = () => {
  const isMobile = useMediaQuery('(max-width: 600px)');
  const navigate = useNavigate();
  const { unclosedFinancialAccounts, total, financialAccountsIsLoading } =
    useHomeLists();

  const viewMoreAccBool = useBoolean();

  const lengthAccountName = isMobile ? 9 : 20;

  const accounts = viewMoreAccBool.value
    ? unclosedFinancialAccounts
    : unclosedFinancialAccounts.slice(0, 3);

  const navigateToAccount = (id: string) => {
    navigate(appRoutes.accountById(id));
  };

  const renderFinancialAccRow = (acc: FinancialAccount) => (
    <TableCell
      component='div'
      scope='row'
      classes={{ root: styles.cellAccount }}
    >
      <img
        src={suitcaseIcon}
        className={styles.cellAccountIcon}
      />
      <div className={styles.cellAccountBox}>
        <Typography>
          {formatAccountName(acc.name, lengthAccountName)}
        </Typography>
        <Typography className={styles.cellAccountBoxLast4}>
          ({acc.accountNumber?.slice(-4)})
        </Typography>
      </div>
    </TableCell>
  );

  const renderBalanceRow = (acc: FinancialAccount) => (
    <TableCell
      component='div'
      scope='row'
      classes={{ root: styles.cellBalance }}
    >
      {!isMobile && (
        <Typography className={styles.cellBalanceText}>
          Available Balance
        </Typography>
      )}
      <Typography className={styles.cellBalanceCash}>
        ${helper.moneyFormat(acc.availableCash?.value) || ''}
      </Typography>
    </TableCell>
  );

  const renderBtnViewRow = (acc: FinancialAccount) =>
    !isMobile && (
      <TableCell
        component='div'
        scope='row'
        classes={{ root: styles.cellBtnView }}
      >
        <Button
          variant='text'
          onClick={() => navigateToAccount(acc.financialAccountId)}
        >
          View Details
        </Button>
      </TableCell>
    );

  const renderBtnViewMore = () => {
    if (unclosedFinancialAccounts?.length < 3) return;

    const countAcc = unclosedFinancialAccounts.length - 3;
    const labelBtn = viewMoreAccBool.value
      ? 'View Less'
      : `View More (${countAcc})`;

    return (
      <Button
        variant='text'
        onClick={viewMoreAccBool.toggle}
        endIcon={
          viewMoreAccBool.value ? (
            <ArrowDropUpOutlinedIcon />
          ) : (
            <ArrowDropDownOutlinedIcon />
          )
        }
      >
        {labelBtn}
      </Button>
    );
  };

  return financialAccountsIsLoading ? (
    <Skeleton
      width='100%'
      height={isMobile ? '400px' : '200px'}
    />
  ) : (
    <TransitionAnimation>
      {!!unclosedFinancialAccounts?.length ? (
        <Table
          classes={{ root: styles.table }}
          component='div'
        >
          {isMobile && (
            <Button
              variant='text'
              className={styles.btnAdd}
            >
              + Add Account
            </Button>
          )}
          <TableBody
            classes={{ root: styles.tableBody }}
            component='div'
          >
            {accounts?.map((acc, index) => (
              <TransitionAnimation key={acc.financialAccountId}>
                <TableRow
                  key={acc.financialAccountId}
                  component='div'
                  classes={{ root: styles.tableBodyRow }}
                  onClick={() => navigateToAccount(acc.financialAccountId)}
                >
                  {renderFinancialAccRow(acc)}
                  {renderBalanceRow(acc)}
                  {renderBtnViewRow(acc)}
                </TableRow>
                {isMobile && index !== accounts.length - 1 && <Divider />}
              </TransitionAnimation>
            ))}
          </TableBody>
          <TableFooter
            classes={{ root: styles.tableFooter }}
            component='div'
          >
            {renderBtnViewMore()}
            <Divider />
            <div className={styles.tableFooterBox}>
              <Typography className={styles.tableFooterBoxText}>
                Total:
              </Typography>
              <Typography>
                ${helper.moneyFormat(total.availableCash)}
              </Typography>
            </div>
          </TableFooter>
        </Table>
      ) : (
        <EmptyTable
          icon={<BankIcon className={styles.emptyIcon} />}
          title='You don’t have any financial accounts'
          subTitle='Your financial accounts will be displayed here once added.'
        />
      )}
    </TransitionAnimation>
  );
};
