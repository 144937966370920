import { useCallback } from "react"
import { useSearchParams } from "react-router-dom"
import { Tabs } from "shared/ui/tabs"
import { NeteviaTab } from "../NeteviaTab"
import { OtherBanksTab } from "../OtherBanksTab"
import { useIsOthereBankTab } from "pages/home/hooks"
import { BANK_TAB } from "pages/home/constants"
import { BANKS_TAB } from "pages/home/enums"

const NETEVIA_TAB = {
  id: BANKS_TAB.NETEVIA_BANK,
  label: 'Netevia',
  component: <NeteviaTab />
}

const OTHER_BANKS_TAB = {
  id: BANKS_TAB.OTHER_BANKS,
  label: 'Other Banks',
  component: <OtherBanksTab />
}

const TABS = [NETEVIA_TAB, OTHER_BANKS_TAB]

export const AccountTabs = () => {
  const [_, setSearchParams] = useSearchParams();
  const isOtherBankTab = useIsOthereBankTab();

  const handleChangeTab = useCallback((tabId: string) => {
    setSearchParams({
      [BANK_TAB]: tabId
    })
  }, [BANK_TAB]);

  return (
    <Tabs 
      data={TABS} 
      initialTab={isOtherBankTab ? OTHER_BANKS_TAB.id : undefined}
      onChangeTab={handleChangeTab} 
    />
  )
}
