import { createDecipheriv, createHash } from 'crypto-browserify';
import { getLocalStorageObject, LocalStorageKeys } from './local-storage';

export const base64Encode = (plainText: string) =>
  Buffer.from(plainText, 'utf8').toString('base64');

export async function aesDecrypt(
  encryptedData: string,
): Promise<string | null> {
  return new Promise((resolve, reject) => {
    try {
      const userIdLs = getLocalStorageObject(LocalStorageKeys.dmU);
      const key = Buffer.from(
        base64Encode(`Users ID -${userIdLs}`).slice(0, 16),
        'utf8'
      );

      const ivLs = getLocalStorageObject(LocalStorageKeys.dmVy);
      const iv = Buffer.from(ivLs, 'utf8');


      const decipher = createDecipheriv('aes-128-cbc', key, iv)
      let decryptedData = '';

      decipher.on('data', (chunk) => {
        decryptedData += chunk.toString('utf8');
      });

      decipher.on('end', () => {
        resolve(decryptedData);
      });

      decipher.on('error', (err) => {
        reject(err);
      });

      const decodedData = Buffer.from(encryptedData, 'base64');
      decipher.write(decodedData);
      decipher.end();
    } catch (error) {
      reject(error);
    }
  });
}
