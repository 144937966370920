import { useFinancialAccounts } from "api/hooksApi/useFinancialAccounts";
import { Skeleton } from "components/skeleton";
import styles from './styles.module.scss'
import { AccountTabs } from "./components/AccountTabs";
import { usePermission } from "shared/hooks/usePermission";
import { NeteviaTab } from "./components/NeteviaTab";

const AccountBalance = () => {
  const { financialAccountsIsLoading } = useFinancialAccounts();
  const { otherBanksPermision } = usePermission();

  if (financialAccountsIsLoading) {
    return (
      <div className={styles.wrapper}>
        <Skeleton width="100%" height="100%" />
      </div>
    )
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        {otherBanksPermision ? <AccountTabs /> : <NeteviaTab />}
      </div>
    </div>
  );
};

export default AccountBalance;

