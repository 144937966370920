import { Button } from 'shared/ui/buttons';
import { FilterByName } from 'pages/accountComponent/components/filterTransactions/componentns/filterByName';
import { FilterIcon } from 'shared/ui/icons';
import FilterListIcon from '@mui/icons-material/FilterList';
import styles from './style.module.scss';
import { useMediaQuery } from '@mui/material';
import { TransitionAnimation } from 'shared/ui/animations';
import { UseBooleanReturn } from 'shared/hooks/useBoolean';

interface FilterControlProsp {
  isLoading: boolean;
  searchCardByFunctionality: string;
  setSearchCardByFunctionality: (searchTransactionByName: string) => void;
  filterDrawerBool: UseBooleanReturn;
}

export const FilterControl = (props: FilterControlProsp) => {
  const {
    isLoading,
    searchCardByFunctionality,
    setSearchCardByFunctionality,
    filterDrawerBool,
  } = props;

  const isMobile = useMediaQuery('(max-width: 600px)');

  return (
    <TransitionAnimation>
      <div className={styles.wrapper}>
        <FilterByName
          searchTransactionByName={searchCardByFunctionality}
          setSearchTransactionByName={setSearchCardByFunctionality}
        />
        <Button
          onClick={() => filterDrawerBool.setTrue()}
          variant='outlined'
          className={styles.btnFilterCard}
          disabled={isLoading}
        >
          {isLoading ? <FilterListIcon /> : <FilterIcon />}
          {!isMobile && 'filter'}
        </Button>
      </div>
    </TransitionAnimation>
  );
};
