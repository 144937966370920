import React, { PropsWithChildren, useContext, useMemo, useState } from 'react';
import { requireNotNull } from '../../../../shared/utils/require-not-null';
import { ModeHomeLists } from './types';
import { useFinancialAccounts } from 'api/hooksApi/useFinancialAccounts';
import {
  FinancialAccount,
  FinancialAccountTotal,
} from 'api/endpoints/financialAccounts';
import {
  PaymentCard,
  usePaymentCardsControllerQuery,
} from 'api/endpoints/paymentCards';
import { filteredCards } from 'pages/accountComponent/lib/filteredCards';
import { FilterCards } from 'pages/accountComponent/types';
import { filterCardsByFunctionality } from 'pages/accountComponent/lib/filterCardsByFunctionality';
import { useCurrentUser, User } from 'providers/user.provider';
import { useBoolean, UseBooleanReturn } from 'shared/hooks/useBoolean';
interface ContextProps {
  modeHomeList: ModeHomeLists; // 'Accounts' | 'Cards';
  changeModeHomeList(newMode: ModeHomeLists): void;

  financialAccounts: FinancialAccount[];
  unclosedFinancialAccounts: FinancialAccount[];
  total: FinancialAccountTotal;
  financialAccountsIsLoading: boolean;
  cards: PaymentCard[];
  cardsIsLoading: boolean;
  searchCardByFunctionality: string;
  setSearchCardByFunctionality: (value: string) => void;
  filterCards: FilterCards;
  setFilterCards: (filter: FilterCards) => void;
  homeCards: PaymentCard[];
  filterDrawerBool: UseBooleanReturn;
}

const Context = React.createContext<ContextProps | undefined>(undefined);

export const HomeListsProvider = ({ children }: PropsWithChildren) => {
  const {
    unclosedFinancialAccounts,
    financialAccounts,
    total,
    financialAccountsIsLoading,
  } = useFinancialAccounts();
  const { subUserRights, user, userIsLoading } = useCurrentUser();
  const { data: paymentCards, isFetching: paymentCardsIsFetching } =
    usePaymentCardsControllerQuery();

  const hasLimitedCardAccess = subUserRights(
    {
      viewAllCardsAndFinancialAccounts: false,
      mainAccess: false,
    },
    { matchAll: true }
  );

  const [modeHomeList, setModeHomeList] = useState<ModeHomeLists>(
    ModeHomeLists.Accounts
  );

  const [filterCards, setFilterCards] = useState<FilterCards>(
    hasLimitedCardAccess ? FilterCards.MyCards : FilterCards.AllAvailableCards
  );

  const [searchCardByFunctionality, setSearchCardByFunctionality] =
    useState<string>('');

  const filterDrawerBool = useBoolean();

  const value = useMemo((): ContextProps => {
    const selectedFilterHome = hasLimitedCardAccess
      ? FilterCards.MyCards
      : FilterCards.AllAvailableCards;

    const homeCards = filteredCards(
      paymentCards || [],
      selectedFilterHome,
      user as User,
      hasLimitedCardAccess
    );

    const temptCards = filteredCards(
      paymentCards || [],
      filterCards,
      user as User,
      hasLimitedCardAccess
    );

    const cards = filterCardsByFunctionality(
      temptCards,
      searchCardByFunctionality
    );

    return {
      modeHomeList,
      changeModeHomeList(newMode: ModeHomeLists) {
        setModeHomeList(newMode);
      },

      financialAccounts,
      unclosedFinancialAccounts,
      total,
      financialAccountsIsLoading: financialAccountsIsLoading || userIsLoading,
      cards,
      cardsIsLoading: paymentCardsIsFetching || userIsLoading,
      searchCardByFunctionality,
      setSearchCardByFunctionality,
      filterCards,
      setFilterCards,
      homeCards,
      filterDrawerBool,
    };
  }, [
    modeHomeList,
    // modeHomeDrawer,
    financialAccounts,
    unclosedFinancialAccounts,
    financialAccountsIsLoading,
    paymentCards,
    paymentCardsIsFetching,
    searchCardByFunctionality,
    filterCards,
    filterDrawerBool,
    userIsLoading,
  ]);

  return <Context.Provider value={value}>{children}</Context.Provider>;
};

export const useHomeLists = () => requireNotNull(useContext(Context));
