import { useMemo } from "react";
import { useParams } from "react-router-dom";
import { useExternalAccounts } from "./useExternalAccounts";

export const useExternalAccountById = () => {
  const { bankId } = useParams();

  const { accountsList, isLoading } = useExternalAccounts();

  const account = useMemo(
    () =>
      accountsList
        .find((fa) => fa.id === bankId),
      [accountsList, bankId]
  );

  return {
    isLoading,
    account: account
  }
}
