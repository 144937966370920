import { Typography } from "@mui/material"
import { BankIcon } from "shared/ui/icons"

import styles from './style.module.scss'

interface BankNameProps {
  bankName: string;
}

const BankName = ({ bankName }: BankNameProps) => {
  return (
    <div className={styles.bankName}>
      <BankIcon />
      <Typography>{bankName}</Typography>
    </div>
  )
}

export default BankName;