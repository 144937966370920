import { FC } from "react";
import styled from "styled-components";
import { Typography } from "@mui/material";
import exclamationIcon from "assets/svg/exclamation-mark-blue.svg";
import TooltipChildren from "shared/components/toolTip/toolTipChildren";

interface ItemBalanceProps {
  balance: string;
  description: string;
  tooltip: string;
}
const ItemBalance: FC<ItemBalanceProps> = ({
  balance,
  description,
  tooltip,
}) => {
  return (
    <Wrapper>
      <BalanceText>${balance}</BalanceText>
      <Box>
        <DescriptionText>{description}</DescriptionText>
        <TooltipChildren description={tooltip}>
          <img src={exclamationIcon} />
        </TooltipChildren>
      </Box>
    </Wrapper>
  );
};

export default ItemBalance;

const Wrapper = styled("div")`
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
`;
const Box = styled("div")`
  display: flex;
  flex-direction: row;
  gap: 4px;
  align-items: center;
`;
const BalanceText = styled(Typography)`
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 133.4%;
  
  @media (min-width: 1170px) {
    font-size: 28px;
  }
`;
const DescriptionText = styled(Typography)`
  color: #949494;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 143%;
  letter-spacing: 0.17px;
`;
