import { Tabs, Tab } from '@mui/material';
import { ModeHomeLists } from '../types';
import { Button } from 'shared/ui/buttons';
import { useHomeLists } from '../provider';
import { useMediaQuery } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import styles from './styles.module.scss';

export const HomeTableTopPanel = () => {
  const isMobile = useMediaQuery('(max-width: 600px)');
  const [_, setSearchParams] = useSearchParams();
  const { modeHomeList, changeModeHomeList } = useHomeLists();

  const handleChange = (
    event: React.SyntheticEvent,
    newValue: ModeHomeLists
  ) => {
    changeModeHomeList(newValue);
  };
  const itsAccount = modeHomeList === ModeHomeLists.Accounts;
  const modes = [ModeHomeLists.Accounts, ModeHomeLists.Cards];

  const addNew = () => {
    itsAccount
      ? setSearchParams({ drawerMode: 'addAccount' }) //changeModeHomeDrawer('addAccount')
      : setSearchParams({ mode: 'openCard' });
  };

  const renderButton = () => (
    <Button
      variant='text'
      onClick={addNew}
    >
      + Add {itsAccount ? 'Account' : 'Card'}
    </Button>
  );

  return (
    <div className={styles.container}>
      <Tabs
        value={modeHomeList}
        onChange={handleChange}
        classes={{
          indicator: styles.tabIndicator,
          root: styles.tabsRoot,
        }}
      >
        {modes.map((mode) => (
          <Tab
            key={mode}
            label={mode}
            value={mode}
            classes={{ selected: styles.tabSelected, root: styles.tabRoot }}
          />
        ))}
      </Tabs>
      {!isMobile && renderButton()}
    </div>
  );
};
