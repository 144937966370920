import { useMediaQuery } from '@mui/material';
import { useCurrentUser } from '../../providers/user.provider';
import { AnimateWrapper } from '../../components/animate-wrapper';
import AccountBalance from './components/accountBalance';
import { usePermission } from 'shared/hooks/usePermission';
import { LoginAs } from './components/loginAs';
import { HomeDiagrams } from './components/diagrams';
import { HomeSlider } from './components/homeSlide';
import ExternalAccountList from './components/externalAccountList';
import { useIsOthereBankTab } from './hooks';
import { Loans } from './components/loans';
import styles from './style.module.scss';
import { HomeLists } from './components/homeLists';import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

const Home = () => {
  const isMobile = useMediaQuery('(max-width: 600px)');
  const { cashflowPermission, homeBannerPermission, otherBanksPermision } = usePermission();
  const { isConsumer, isMainBusiness } = useCurrentUser();
  const isOtherBankTab = useIsOthereBankTab();
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (!otherBanksPermision && isOtherBankTab) {
      setSearchParams({})
    }
  }, [searchParams, isOtherBankTab, otherBanksPermision])

  const renderHeader = isConsumer ? (
    <div className={styles.staticTitle}>Welcome to Netevia.</div>
  ) : (
    <LoginAs />
  );

  const renderContent = () => {
    return !isOtherBankTab ? (
      <div className={styles.content}>
        <div className={styles.box}>
          <AccountBalance />
          <HomeLists />
          {!isMobile && cashflowPermission && <HomeDiagrams />}
        </div>
        <div className={styles.widgetBox}>
          {!isMobile && homeBannerPermission && <HomeSlider />}
          {isMainBusiness && <Loans />}
          {isMobile && homeBannerPermission && <HomeSlider />}
          {isMobile && cashflowPermission && <HomeDiagrams />}
        </div>
      </div>
    ) : (
      <div className={styles.contentOther}>
        <AccountBalance />
        <ExternalAccountList />
      </div>
    );
  };

  return (
    <AnimateWrapper className='fade'>
      <div className={styles.container}>
        {renderHeader}
        {renderContent()}
      </div>
    </AnimateWrapper>
  );
};
export default Home;
