import { useEffect } from 'react';
import { TransitionAnimation } from 'shared/ui/animations';
import { Typography } from 'shared/ui/typography';
import ArrowLeft from 'assets/svg/arrow-left-gray.svg';
import { useNavigate } from 'react-router-dom';
import { appRoutes } from 'routes';
import { useMediaQuery } from '@mui/material';
import { useHomeLists } from 'pages/home/components/homeLists/provider';
import styles from './style.module.scss';
import { TableDesctop } from './components/tableDesctop';
import { TableMob } from './components/tableMob';
import { FilterControl } from './components/filterControl';
import { NewCardFromAccount } from 'pages/accountComponent/Actions/NewCardFromAccount';
import { FilterForCards } from 'pages/accountComponent/components/filterForCards';
import DrawerModal from 'shared/components/drawerModal';

export const AllCards = () => {
  const isMobile = useMediaQuery('(max-width: 600px)');
  const navigate = useNavigate();

  const {
    cards,
    cardsIsLoading,
    searchCardByFunctionality,
    setSearchCardByFunctionality,
    filterCards,
    setFilterCards,
    filterDrawerBool,
  } = useHomeLists();

  return (
    <TransitionAnimation>
      <div className={styles.container}>
        <div className={styles.containerNav}>
          <img
            src={ArrowLeft}
            onClick={() => navigate(appRoutes.home())}
          />
          <Typography className={styles.containerNavTitle}>
            All cards
          </Typography>
        </div>

        <NewCardFromAccount />
        {!isMobile ? (
          <TableDesctop
            cards={cards}
            cardsIsLoading={cardsIsLoading}
            searchCardByFunctionality={searchCardByFunctionality}
            setSearchCardByFunctionality={setSearchCardByFunctionality}
            filterDrawerBool={filterDrawerBool}
          />
        ) : (
          <>
            <FilterControl
              isLoading={cardsIsLoading}
              searchCardByFunctionality={searchCardByFunctionality}
              setSearchCardByFunctionality={setSearchCardByFunctionality}
              filterDrawerBool={filterDrawerBool}
            />
            <TableMob
              cards={cards}
              cadsIsLoading={cardsIsLoading}
            />
          </>
        )}
        <DrawerModal
          isShow={filterDrawerBool.value}
          onClose={() => filterDrawerBool.setFalse()}
          titleText={'Filter cards'}
        >
          <FilterForCards
            filterCards={filterCards}
            setFilterCards={setFilterCards}
            filterDrawerBool={filterDrawerBool}
          />
        </DrawerModal>
      </div>
    </TransitionAnimation>
  );
};
