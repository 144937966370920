import { AnimateWrapper } from '../../components/animate-wrapper';
import styles from './style.module.scss';
import { SvgIcon, SvgIconType } from '../../shared/components/svgIcon';
import { useBoolean } from '../../shared/hooks/useBoolean';
import { useMemo } from 'react';
import cx from 'classnames';
import { Checkbox } from '@mui/material';
import { RadioButtonChecked, RadioButtonUnchecked } from '@mui/icons-material';
import { Button } from '../../shared/ui/buttons';
import { appRoutes } from '../../routes';
import { useNavigate } from 'react-router-dom';
import { LoginLayoutRender } from 'layouts/LoginLayout';

type DataItem = [icon: SvgIconType, title: string, desc: string];

const businessFeatures: DataItem[] = [
  [
    'MonetizationonOutlined',
    '1% Cash Back',
    'Earn unlimited 1% cash back on purchases.',
  ],
  [
    'AccountBalanceWalletOutlined',
    'Earn on Balance',
    'Earn 2% annually on your balances.',
  ],
  [
    'CardGiftcardOutlined',
    'Welcome Bonus',
    'We match 1% of your deposits during the first 90 days, up to $100,000.',
  ],
  [
    'MoneyOutlined',
    'Instant Funding',
    'Instant access to your sales when combining payments & banking services.',
  ],
  [
    'FileCopyOutlined',
    'Free Tax Filing',
    'Simplify tax season with our complimentary tax filing services.',
  ],
];

const personalFeatures: DataItem[] = [
  [
    'MonetizationonOutlined',
    'Get Paid Early',
    'Access your money ahead of time for better expense management or emergencies.',
  ],
  [
    'FileCopyOutlined',
    'Free Tax Filing',
    'Simplify tax season with our complimentary tax filing services.',
  ],
];

export const SignUp = () => {
  const businessFeatureActiveBool = useBoolean(true);
  const navigate = useNavigate();

  const features = useMemo((): [
    feature: string,
    data: DataItem[],
    isActive: boolean,
  ][] => {
    return [
      ['Business', businessFeatures, businessFeatureActiveBool.value],
      ['Personal', personalFeatures, !businessFeatureActiveBool.value],
    ];
  }, [businessFeatureActiveBool.value]);

  const handleSignUpClick = () => {
    navigate(
      businessFeatureActiveBool.value
        ? appRoutes.signUpBusiness()
        : appRoutes.signUpPersonal()
    );
  };

  return (
    <LoginLayoutRender>
      <AnimateWrapper className='fade'>
        <div className={styles.box}>
          <div className={styles.container}>
            <div className={styles.containerTitle}>
              Choose an&nbsp;account type you&nbsp;would
              like&nbsp;to&nbsp;create
            </div>
            <div className={styles.features}>
              {features.map(([title, data, isActive]) => (
                <div
                  className={styles.featureItemWrapper}
                  key={title}
                >
                  <div
                    style={{ pointerEvents: isActive ? 'none' : 'initial' }}
                    onClick={businessFeatureActiveBool.toggle}
                    tabIndex={0}
                    className={styles.featureName}
                  >
                    <div
                      className={cx(
                        styles.featureNameContent,
                        isActive && styles.featureNameContentActive
                      )}
                    >
                      <div>{title}</div>
                      <Checkbox
                        icon={<RadioButtonUnchecked />}
                        checkedIcon={<RadioButtonChecked />}
                        checked={isActive}
                        onChange={() => {}}
                      />
                    </div>
                  </div>
                  <div
                    className={cx(
                      styles.featuresContent,
                      !isActive && styles.featuresContentCollapsed
                    )}
                  >
                    <div style={{ height: '8px' }} />
                    {data.map(
                      ([svgIconType, featureTitle, featureDescription]) => (
                        <div
                          key={featureTitle}
                          className={styles.featureItem}
                        >
                          <div className={styles.featureItemIcon}>
                            <SvgIcon
                              name={svgIconType}
                              sizePx={24}
                            />
                          </div>
                          <div>
                            <div className={styles.featureItemTitle}>
                              {featureTitle}
                            </div>
                            <div className={styles.featureItemSubtitle}>
                              {featureDescription}
                            </div>
                          </div>
                        </div>
                      )
                    )}
                    <div style={{ height: '16px' }} />
                  </div>
                </div>
              ))}
            </div>
            <div className={styles.controls}>
              <div>
                <Button
                  variant='text'
                  href={appRoutes.login()}
                >
                  Back
                </Button>
              </div>
              <div>
                <Button
                  fullWidth
                  color='primary'
                  variant='contained'
                  onClick={handleSignUpClick}
                >
                  Next
                </Button>
              </div>
            </div>
          </div>
        </div>
      </AnimateWrapper>
    </LoginLayoutRender>
  );
};
