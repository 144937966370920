import { Button } from "shared/ui/buttons"

import styles from './style.module.scss'
import { useNavigateToExternal } from "pages/home/hooks";

interface ButtonViewDetailsProps {
  bankId: string;
}

const ButtonViewDetails = ({ bankId }: ButtonViewDetailsProps) => {
  const navigateToExternalAccount = useNavigateToExternal();

  return (
    <Button 
      variant="text" 
      className={styles.btn} 
      onClick={() => navigateToExternalAccount(bankId)}
    >
      View Details
    </Button>
  )
}

export default ButtonViewDetails;