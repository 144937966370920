import { useCallback, useMemo } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { appRoutes } from "routes"
import { BANK_TAB } from "./constants";
import { BANKS_TAB } from "./enums";

export const useIsOthereBankTab = () => {
  const [searchParams] = useSearchParams();

  return useMemo(() => searchParams.get(BANK_TAB) === BANKS_TAB.OTHER_BANKS, [searchParams]) 
}

export const useNavigateToExternal = () => {
  const navigate = useNavigate();

  const navigateToExternalAccount = useCallback((bankId: string) => {
    navigate(appRoutes.othereBankById(bankId));
  }, []);

  return navigateToExternalAccount;
}