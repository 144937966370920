import { TransitionAnimation } from "shared/ui/animations";
import { ListButton } from "shared/ui/lists";
import { Button } from "shared/ui/buttons";
import { ChevronRightIcon, UnlinkIcon } from "shared/ui/icons";
import styles from './style.module.scss';
import { useConfirmDialog } from "providers/confirm.provider";
import { useDeleteExternalPayeeMutation } from "api/endpoints/fundsMovement";
import { useExternalAccountById } from "api/hooksApi/useExternalAccountById";
import { ExternalAccount } from "api/endpoints/financialAccounts";
import helper from "services/helper";
import { useSnackBar } from "providers/snackBar.provider";
import { useNavigate } from "react-router-dom";
import { appRoutes } from "routes";
import { BANKS_TAB } from "pages/home/enums";

const getBankNameAndLast4 = (account: ExternalAccount | undefined) => {
  if (!account) return '';

  const { bankName, externalBankAccountDetails: { last4 } } = account;

  return `${bankName} (${last4})`;
}  

const getDialogText = (bankAccount: string) => {
  return `Are you sure you want to unlink the ${bankAccount} bank account? 
  By confirming, the external bank account will be removed from Netevia.`
}

const getDeleteAccountMessage = (bankAccount: string) => 
  `The ${bankAccount} bank account has been successfully unlinked from Netevia.`;

export const More = () => {
  const { account }  = useExternalAccountById();

  const navigate = useNavigate();
  const { setSnackBar } = useSnackBar();
  const confirm = useConfirmDialog();
  const [deleteExternalPayee] = useDeleteExternalPayeeMutation()
    
  const confirmModal = () => {
    confirm.show({
      applyButtonText: 'Confirm',
      cancelButtonText: 'Cancel',
      dialogTitle: 'Unlink account',
      dialogText: getDialogText(getBankNameAndLast4(account)),
      cancelButtonType: 'text',
      cancelError: true,
      typeNew: true,
      async onApply() {
        try {
          await deleteExternalPayee({
            financialAccountId: account?.id ?? '',
            force: true,
          }).unwrap()
    
          setSnackBar({
            type: 'success',
            message: getDeleteAccountMessage(getBankNameAndLast4(account)),
            isShow: true,
          })
          
          navigate(appRoutes.home(undefined,{ bankTab: BANKS_TAB.OTHER_BANKS }))
        } catch (e: any) {
          setSnackBar({
            type: 'error',
            message: helper.formatErrors(e.data),
            isShow: true,
          })
        }
      },
    });
  };

  return (
    <TransitionAnimation>
      <div>
        <ListButton className={styles.listBtn}>
          <Button
            startIcon={<UnlinkIcon />}
            endIcon={<ChevronRightIcon />}
            onClick={confirmModal}
          >
            Unlink account
          </Button>
        </ListButton>
      </div>
    </TransitionAnimation>
  );
}
