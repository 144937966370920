import { useExternalAccounts } from "api/hooksApi/useExternalAccounts"
import TableBlock from "shared/components/TableBlock";
import AddExternalBank from "../AddExternalBank";
import helper from "services/helper";
import { Typography } from "@mui/material";
import ButtonViewDetails from "../ButtonViewDetails";
import BankName from "../BankName";
import NoContent from "../NoContent";
import { useNavigateToExternal } from "pages/home/hooks";

import styles from '../../style.module.scss'

const tabCells = () => [
  {
    label: "Bank account",
    name: "bankName",
    transform: (bankName: string) => 
      <BankName bankName={bankName} />
  },
  {
    label: "Available balance",
    name: "balanceInfo",
    transform: (balance) => (
      <Typography>
        ${helper.moneyFormat(balance?.value) || ''}
      </Typography>
    ),
  },
  {
    label: '',
    name: 'id',
    props: { align: 'right' },
    transform: (bankId: string) => {
      return <ButtonViewDetails bankId={bankId} />
    }
  }
]

export const AccountsList = () => {
  const { accountsList } = useExternalAccounts();
  const navigateToExternalAccount = useNavigateToExternal();

  return (
    <div className={styles.container}>
      <TableBlock
        rows={accountsList}
        tabCells={tabCells()}
        title='External bank accounts'
        Action={AddExternalBank}
        NoContent={NoContent}
        onClick={navigateToExternalAccount}
        isRowsClickable
        hasMobileVersion={false}
      />
    </div>
  )
}