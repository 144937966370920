import { ListButton } from '../../shared/ui/lists';
import styles from '../../pages/settings/AuthorizedSubUser/style.module.scss';
import {
  AttachedPaymentCard,
  SubUsersApi,
} from '../../api/endpoints/subProfiles';
import { Button } from '../../shared/ui/buttons';
import { ChevronRightIcon } from '../../shared/ui/icons';
import { Typography } from '../../shared/ui/typography';
import { TransitionAnimation } from '../../shared/ui/animations';
import { useCallback } from 'react';
import { Status } from '../../enums';

interface Props {
  data: SubUsersApi[];

  onClick(subUserId: number): void;
}

export const SubUsersList = ({ data, onClick }: Props) => {
  const getConnectedCards = useCallback((item: SubUsersApi) => {
    return (
      (item.attachedPaymentCards &&
        item.attachedPaymentCards.length > 0 &&
        item.attachedPaymentCards.filter(
          (pc) => pc.status !== Status.Closed
        )) ||
      undefined
    );
  }, []);
  return (
    <TransitionAnimation>
      <ListButton className={styles.listBtn}>
        {data.map((item: SubUsersApi) => {
          const cards = getConnectedCards(item);
          return (
            <Button
              key={item.id}
              endIcon={<ChevronRightIcon />}
              onClick={() => onClick(item.id)}
            >
              <div className={styles.boxText}>
                <Typography>
                  {item.givenName} {item.familyName}
                </Typography>
                {cards && (
                  <Typography className={styles.subLabel}>
                    {cards.length + ' connected cards'}
                  </Typography>
                )}
              </div>
            </Button>
          );
        })}
      </ListButton>
    </TransitionAnimation>
  );
};
