import { TransitionAnimation } from 'shared/ui/animations';
import { useFormattedHolderData } from 'shared/lib/hooks/useFormattedHolderData';
import { Skeleton } from 'components/skeleton';
import { Typography } from 'shared/ui/typography';
import { TextInput } from 'shared/ui/inputs';
import { useHolderV2ControllerQuery } from 'api/endpoints/account';
import { ViewAddress } from '../viewAddress';
import { Button } from 'shared/ui/buttons';
import {
  useLazyLoanRequestQuery,
  useLoanSettingsQuery,
} from 'api/endpoints/loans';
import { useSnackBar } from 'providers/snackBar.provider';
import { useDrawerStepperContext } from 'shared/ui/drawer-stepper';
import moment from 'moment';
import helper from 'services/helper';
import styles from './styles.module.scss';

interface ConfirmFundingApplicationProps {
  loanAmount: string;
  financialAccountId: string;
}

export const ConfirmFundingApplication = (
  props: ConfirmFundingApplicationProps
) => {
  const { loanAmount, financialAccountId } = props;
  const { close, lockForClose, unlockForClose } = useDrawerStepperContext();
  const { setSnackBar } = useSnackBar();
  const { data: holder } = useHolderV2ControllerQuery();
  const { formattedHolderData, formattedholderDataIsLoading } =
    useFormattedHolderData();
  const { refetch: refetchLoanSettings } = useLoanSettingsQuery();
  const [loanRequest, { isLoading: isLoadingGetLoanRequest }] =
    useLazyLoanRequestQuery();

  const renderOwnerAddress = () => {
    return formattedHolderData?.owners?.map((owner) => (
      <ViewAddress
        key={owner.ownerType}
        title={`${
          owner.ownerType === 1 ? 'Primary' : owner.givenName
        } Owner address`}
        addressInfo={owner.info.addressInfo}
      />
    ));
  };

  const getLoanRequest = async () => {
    try {
      lockForClose();
      const res = await loanRequest({
        loanAmount,
        financialAccountId,
      }).unwrap();

      if (res) {
        window.open(res, '_blank');
      }
      refetchLoanSettings();
      unlockForClose();
      close();
    } catch (e: any) {
      unlockForClose();
      setSnackBar({
        type: 'error',
        message: helper.formatErrors(e.data),
        isShow: true,
      });
    }
  };

  return formattedholderDataIsLoading ? (
    <Skeleton
      width='100%'
      height='110px'
    />
  ) : (
    <TransitionAnimation>
      <div className={styles.container}>
        <div className={styles.box}>
          <Typography>Personal information</Typography>
          <TextInput
            label='Name'
            value={holder?.businessAccountHolder.givenName}
            disabled
          />
          <TextInput
            label='Last Name'
            value={holder?.businessAccountHolder.familyName}
            disabled
          />
          <TextInput
            label='Date of birth'
            value={moment(holder?.businessAccountHolder.dateOfBirth).format(
              'MM/DD/YYYY'
            )}
            disabled
          />
        </div>
        <ViewAddress
          title='Business address'
          addressInfo={formattedHolderData?.bussinessInfo.addressInfo!}
        />
        {renderOwnerAddress()}
        <div className={styles.boxContact}>
          <Typography>Contact information</Typography>
          <TextInput
            label='Phone number'
            value={formattedHolderData?.bussinessInfo?.phoneInfo.phone}
            disabled
          />
          <TextInput
            label='Email address'
            value={formattedHolderData?.bussinessInfo?.emailInfo.email}
            disabled
          />
        </div>
        <Button
          className={styles.btn}
          onClick={getLoanRequest}
          loading={isLoadingGetLoanRequest}
        >
          Request Application
        </Button>
      </div>
    </TransitionAnimation>
  );
};
