import { Skeleton } from "@mui/material";
import { useExternalAccountById } from "api/hooksApi/useExternalAccountById";
import BankIcon from "assets/svg/Bank-Icon.svg";
import { Typography } from "shared/ui/typography";
import ItemBalance from "./ItemBalance";
import helper from "services/helper";

import styles from './style.module.scss';

const availableBalance =
  "The amount of money in your account that you can use right now.";

export const AccountBalance = () => {
  const { account, isLoading } = useExternalAccountById();

  if (isLoading) {
    return <Skeleton width={400} height={180}/>
  }

  return (
    <div className={styles.externalBank}>
      <div className={styles.bankName}>
        <img src={BankIcon} alt="Chase logo" className={styles.bankLogo} />
        <Typography variant='h6' >{account?.bankName}</Typography>
      </div>
      
      <ItemBalance 
        balance={helper.moneyFormat(account?.balanceInfo.value)} 
        description={"Available Balance"} 
        tooltip={availableBalance}
      />
    </div>
  );
}