import { useMemo, useState } from 'react';
import { AnimateWrapper } from 'components/animate-wrapper';
import ButtonWithChevron from 'components/button/buttonWithChevron';
import cardImg from 'assets/svg/CreditCardOutlined.svg';
import creditScoreOutlined from 'assets/svg/CreditScoreOutlined.svg';
import burnercCard from 'assets/svg/burnerCard.svg';
import { useTranslationProvider } from 'providers/translation/translation.provider';
import SnackBarWarning from '../snackBar/snackBarWarning';
import useIsUserCanOpenPrimaryCard from 'shared/hooks/useIsUserCanOpenPrimaryCard';
import styles from './style.module.scss';
import { useCurrentUser } from '../../../providers/user.provider';
import { useSubUsersControllerQuery } from '../../../api/endpoints/subProfiles';

interface PropTypes {
  setChooseCardsMode?: (string) => void;
  forSubUserId?: string;
}

const PRIMARY_CARD_OPEN_ERROR_TEXT =
  'Primary card already created. Only one primary is allowed per authorized user.';

const NewProductCard = ({ setChooseCardsMode = () => {}, forSubUserId }: PropTypes) => {
  const { t } = useTranslationProvider();
  const [warningModal, setWarningModal] = useState<string>('');
  const { isConsumer } = useCurrentUser();
  const { canOpenPrimaryCard, hasAvailablePaymentCards } =
    useIsUserCanOpenPrimaryCard();
  const { data: subUsers, isLoading: isLoadingSubUsers, refetch: refetchSubUsers } =
    useSubUsersControllerQuery();

  const navigatetoOpenPrimaryCard = () => {
      setChooseCardsMode('openPrimaryCard');
  };

  const subHasPrimaryCard = useMemo(() => {
    if (!forSubUserId || !subUsers?.length) {
      return false;
    }
    const subUser = subUsers?.find((user) => user.id.toString() === forSubUserId);
    return Boolean(subUser?.attachedPaymentCards?.find(pc => pc.isMainCard));
  }, [isConsumer, forSubUserId, subUsers]);

const businessCanOpenPrimaryCardForSub =  (!subHasPrimaryCard && forSubUserId);

  return (
    <AnimateWrapper className='fade'>
      <div>
        <div className={styles.container}>
          <p className={styles.container_title}>
            {t('NewProduct.Choose one of the options', {
              defaultValue: 'Choose one of the options',
            })}
          </p>
          <div className={styles.container_boxButtons}>
            {canOpenPrimaryCard || businessCanOpenPrimaryCardForSub && (
              <div onClick={() => navigatetoOpenPrimaryCard()}>
                <ButtonWithChevron
                  text={t('NewProduct.Primary card', {
                    defaultValue: 'Primary card',
                  })}
                  img={cardImg}
                />
              </div>
            )}
            <div onClick={() => setChooseCardsMode('physicalCard')}>
              <ButtonWithChevron
                text={t('Home.Physical card', {
                  defaultValue: 'Physical card',
                })}
                img={cardImg}
              />
            </div>
            <div onClick={() => setChooseCardsMode('openVirtualcard')}>
              <ButtonWithChevron
                text={t('Home.Virtual card', {
                  defaultValue: 'Virtual card',
                })}
                img={creditScoreOutlined}
              />
            </div>
            <div onClick={() => setChooseCardsMode('burnerCard')}>
              <ButtonWithChevron
                text={t('Home.Burner card', {
                  defaultValue: 'Burner card',
                })}
                img={burnercCard}
              />
            </div>
          </div>
          {subHasPrimaryCard && !isConsumer && (
            <div className={styles.primaryDisabled}>
              <div style={{ opacity: 0.5 }}>
                <img
                  style={{ width: '24px', height: '24px' }}
                  src={cardImg}
                />
              </div>
              <div className={styles.primaryDisabledText}>
                <div>Primary card</div>
                <div className={styles.primaryDisabledSubtitle}>
                  {PRIMARY_CARD_OPEN_ERROR_TEXT}
                </div>
              </div>
            </div>
          )}
        </div>

        <SnackBarWarning
          isOpen={!!warningModal}
          message={warningModal}
          onClose={() => setWarningModal('')}
        />
      </div>
    </AnimateWrapper>
  );
};

export default NewProductCard;
