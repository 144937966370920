import { Skeleton } from "components/skeleton";
import { useExternalAccounts } from "api/hooksApi/useExternalAccounts";
import { AccountsList } from "./components/AccountsList";

const ExternalAccountList = () => {
  const { isLoading } = useExternalAccounts();
  
  if (isLoading) {
    return <Skeleton width="100%" height="210px" />
  }

  return <AccountsList />
}

export default ExternalAccountList