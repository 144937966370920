import CurrencyDollar from "assets/svg/dollar-blue.svg";
import { Divider, Typography } from "@mui/material";
import helper from "services/helper";
import ItemBalance from "../itemBalance";
import { useFinancialAccounts } from "api/hooksApi/useFinancialAccounts";

import styles from '../../styles.module.scss'

const availableBalance =
  "The amount of money in your account that you can use right now.";
const presentBalance =
  "The total amount of money recorded in your account, including funds not yet available for you to use. This includes pending transactions, authorization holds that are not yet posted or deposits that have not yet been made available.";

export const NeteviaTab = () => {
  const { total } = useFinancialAccounts();

  const renderItems = () => {
    if (total.availableCash === total.cash) {
      return (
        <ItemBalance
          balance={helper.moneyFormat(total.availableCash)}
          description={"Available Balance"}
          tooltip={availableBalance}
        />
      );
    } else {
      return (
        <>
          <ItemBalance
            balance={helper.moneyFormat(total.availableCash)}
            description={"Available Balance"}
            tooltip={availableBalance}
          />
          <Divider 
            orientation="vertical" 
            variant="middle" 
            flexItem
          />
          <ItemBalance
            balance={helper.moneyFormat(total.cash)}
            description={"Present Balance"}
            tooltip={presentBalance}
          />
        </>
      );
    }
  };
  
  return (
    <>
      <div className={styles.header}>
        <img src={CurrencyDollar} />
        <Typography>All accounts</Typography>
      </div>
      <Typography fontSize={14}>{`Here's a summary`} of your financial accounts<br />and&nbsp;balances.</Typography>
      <div className={styles.box}>{renderItems()}</div>
    </>
  )
}