import Img from 'assets/img/connect-external-bank.png';
import LockOutlined from 'assets/svg/LockOutlined.svg';
import { Typography } from 'shared/ui/typography';
import AddIcon from '@mui/icons-material/Add'
import { Button } from 'shared/ui/buttons';
import { useDrawerStepperContext } from 'shared/ui/drawer-stepper';

import styles from './style.module.scss'

const FirstStep = () => {
  const { goNext } = useDrawerStepperContext();

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <img src={Img} />

        <Typography>
          Easily link your external bank account to Netevia! Once connected, you&apos;ll be able to view and manage your bank details right within Netevia.
        </Typography>

        <div className={styles.alert}>
          <img src={LockOutlined} className={styles.img} />

          <Typography variant='h6'>Secure connection</Typography>

          <Typography variant='body1'>Netevia doesn&apos;t store your username and password. Connecting your external account enables you to transfer money to your Netevia account.</Typography>
        </div>

        <Button className={styles.btn} onClick={() => goNext('1')}> 
          <AddIcon /> 
          Connect Account
        </Button>
      </div>
    </div>
  )
}

export default FirstStep;