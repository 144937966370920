import { AnimateWrapper } from "components/animate-wrapper"
import { useNavigate } from "react-router-dom"
import { appRoutes } from "routes"
import SimpleContainer from "shared/components/containers/simpleContainer"
import { AccountHeader } from "./AccountHeader"
import { AccountTransactions } from "./AccountTransactions"
import { FilterTransactionsControls } from "./FilterTransactionsControls"
import { Typography } from "shared/ui/typography"
import { BANKS_TAB } from "pages/home/enums"

import styles from './style.module.scss';

const ExternalAccount = () => {
  const navigate = useNavigate()

  return (
    <SimpleContainer
      title={'Your Account'}
      onTitleClick={() => navigate(appRoutes.home(undefined,{ bankTab: BANKS_TAB.OTHER_BANKS }))}
    >
      <AnimateWrapper className='fade'>
        <div className={styles.container}>
          <AccountHeader />
          <Typography variant='h6'>Transactions</Typography>
          <FilterTransactionsControls />
          <AccountTransactions />
        </div>
      </AnimateWrapper>
    </SimpleContainer>
  )
}

export default ExternalAccount;


