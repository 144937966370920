import { Avatar } from '@mui/material';
import transferIn from 'assets/svg/transferIn.svg';
import transferOut from 'assets/svg/transferOut.svg';
import AccountBalanceOutlined from 'assets/svg/AccountBalanceOutlined.svg';
import cardImg from 'assets/svg/CreditCardOutlined.svg';
import helper from 'services/helper';
import moment from 'moment';
import Badge from 'pages/accountComponent/components/Badge';
import { Chip } from '@mui/material';
import { colors } from '../../../../theme';
import { TransactionHints } from './TransactionHints';

interface TableCells {
  label: string;
  name: string;
  props: {
    align?: string;
    component?: string;
    scope?: string;
  };
  transform?: (val: any, item?: any, styles?: string) => any;
}

export const TransferCells: (
  params?: {
    hideTransactionBalance?: boolean;
  }
) => TableCells[] = (params) => [
  //   {
  //     label: "",
  //     name: "iconUrl",
  //     props: { scope: undefined },
  //     transform: (src: string) => (
  //       <Avatar variant="square" src={src} classes={{ img: "objectFit-fill" }} />
  //     ),
  //   },
  {
    label: 'Transaction',
    name: 'name',
    props: { component: 'th', scope: 'row' },
    transform: (value, item, styles) => (
      <div className={styles}>
        <img src={item.amount.symbol !== '-' ? transferIn : transferOut} />
        {value}
      </div>
    ),
  },
  {
    label: 'Type',
    name: 'type',
    props: { align: 'left' },
    transform: (val, item) => (
      <div>
        {val}
        {item.authorizedUser && (
          <div style={{ marginTop: '4px' }}>
            <Chip
              size='small'
              label={`AU: ${item.authorizedUser}`}
              clickable={false}
            />
          </div>
        )}
      </div>
    ),
  },
  {
    label: 'Status',
    name: 'status',
    props: { align: 'left' },
    transform: (value, item) => <Badge item={item} />,
  },
  {
    label: 'Amount',
    name: 'amount',
    props: { align: 'left' },
    transform: (value, item) => {
      return (
        <div>
          <div style={{
            display: 'flex',
            flexDirection:'row',
            alignItems: 'center',
            gap: '6px'
          }}>
            <div
              style={{
                color: value?.symbol === '-' ? '#000' : '#2E7D32',
                fontWeight: '500',
                fontSize: '20px',
              }}
            >
              {`${value?.symbol}$ ${helper.formatUsdValue(value?.value)}`}
            </div>
            {item?.rewardPoints && 
              <Chip
                sx={{
                  backgroundColor: '#E8F5E9', 
                  color: '#1B5E20',
                  '& .MuiChip-label': { 
                    padding: '0px 6px',
                  },
                }}
                size='small'
                label={`+ ${item?.rewardPoints}`}
                clickable={false}
              />
            }
          </div>
          <TransactionHints
            hideTransactionBalance={params?.hideTransactionBalance}
            transaction={item}
          />
        </div>
      );
    },
  },
  {
    label: 'Card/Account',
    name: 'last4',
    props: { align: 'left' },
    transform: (value, item, styles) => (
      <div className={styles}>
        <img
          src={value?.type === 'ACCOUNT' ? AccountBalanceOutlined : cardImg}
        />
        {`**** ${value?.value ? value.value : ''}`}
      </div>
    ),
  },
  {
    label: 'Date',
    name: 'date',
    props: { align: 'left' },
    transform: (value) => moment(value).format('MM.DD.YYYY, h:mm A'),
  },
];
