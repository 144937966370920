import { ExternalAccount, useExternalAccountsQuery } from "api/endpoints/financialAccounts"
import { useMemo } from "react";

export const useExternalAccounts = () => {
  const { data: financialAccounts = [], isLoading } = useExternalAccountsQuery();
  const unclosedFinancialAccounts = useMemo(
    (): ExternalAccount[] =>
      financialAccounts
        .filter((fa) => fa.accountStatus !== 'CLOSED' && fa.balanceInfo?.value > 0)
        .map(fa => ({
          ...fa,
          balanceInfo: {
            ...fa.balanceInfo,
            value: fa.balanceInfo.value * 100 // transfer to cents
          }
        })),
      [financialAccounts]
  );

  return {
    availableBalance: unclosedFinancialAccounts.reduce((acc, item) => acc += item.balanceInfo.value, 0),
    isLoading,
    accountsList: unclosedFinancialAccounts
  }
}
