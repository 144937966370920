import { useCurrentUser } from 'providers/user.provider';
import { useMemo } from 'react';
import { useFinancialAccounts } from '../../api/hooksApi/useFinancialAccounts';

export const usePermission = () => {
  const { isSubUser, isMainBusiness, subUserRights, isConsumer } = useCurrentUser();
  const { unclosedFinancialAccounts } = useFinancialAccounts();

  const cashflowPermission =
    !isSubUser ||
    subUserRights({
      viewAllCardsAndFinancialAccounts: true,
      mainAccess: true,
    });

  const cardTransactionsPermission =
    !isSubUser ||
    subUserRights({
      openBankingCard: true,
      viewAllCardsAndFinancialAccounts: true,
      mainAccess: true,
    });

  const cardLimitsPermission =
    !isSubUser ||
    subUserRights({
      openBankingCard: true,
      viewAllCardsAndFinancialAccounts: true,
      mainAccess: true,
    });
  const linkedAccountPermission =
    !isSubUser ||
    subUserRights({
      openBankingCard: true,
      viewAllCardsAndFinancialAccounts: true,
      mainAccess: true,
    });

  const pinSetupPermission = true;

  const reissueCardPermission =
    !isSubUser ||
    subUserRights({
      openBankingCard: true,
      mainAccess: true,
    });

  const lockCardPermission = true;
  const closeCardPermission = true;
  const rewardsPermission = !isSubUser && !isConsumer;

  const transferPermission = useMemo(() => {
    if (isConsumer) {
      return unclosedFinancialAccounts.length > 0;
    }

    return !isSubUser ||
      subUserRights({
        makeTransfers: true,
        mainAccess: true,
      });
  }, [isSubUser, isConsumer, unclosedFinancialAccounts])

  const fromConnectedAccountPermission =
    !isSubUser ||
    subUserRights({
      makeTransfers: true,
      mainAccess: true,
    });

  const betweenOwnAccountsPermission =
    !isSubUser ||
    subUserRights({
      makeTransfers: true,
      mainAccess: true,
    });

  const toNeteviaCustomerPermission =
    !isSubUser ||
    subUserRights({
      makeTransfers: true,
      viewAllCardsAndFinancialAccounts: true,
      mainAccess: true,
    });

  const managePayeePermission =
    !isSubUser ||
    subUserRights({
      makeTransfers: true,
      viewAllCardsAndFinancialAccounts: true,
      mainAccess: true,
    });

  const payeeHistoryPermission =
    !isSubUser ||
    subUserRights({
      makeTransfers: true,
      viewAllCardsAndFinancialAccounts: true,
      mainAccess: true,
    });

  const addPayeePermission =
    !isSubUser ||
    subUserRights({
      makeTransfers: true,
      mainAccess: true,
    });

  const connectExternalAccountPermission =
    !isSubUser ||
    subUserRights({
      makeTransfers: true,
      viewAllCardsAndFinancialAccounts: true,
      mainAccess: true,
    });

  const finAccountsPermission =
    !isSubUser ||
    subUserRights({
      makeTransfers: true,
      viewAllCardsAndFinancialAccounts: true,
      mainAccess: true,
    });

  const languagePermission =
    !isSubUser ||
    subUserRights({
      openBankingCard: true,
      openFinancialAccount: true,
      makeTransfers: true,
      viewAllCardsAndFinancialAccounts: true,
      mainAccess: true,
    });

  const notificationsPermission =
    !isSubUser ||
    subUserRights({
      openBankingCard: true,
      openFinancialAccount: true,
      makeTransfers: true,
      viewAllCardsAndFinancialAccounts: true,
      mainAccess: true,
    });

  const notificationDevicesPermission =
    !isSubUser || subUserRights({ mainAccess: true });

  const primaryAccountPermission =
    isMainBusiness || subUserRights({ mainAccess: true });

  const faqPermission =
    !isSubUser ||
    subUserRights({
      openBankingCard: true,
      openFinancialAccount: true,
      makeTransfers: true,
      viewAllCardsAndFinancialAccounts: true,
      mainAccess: true,
    });

  const createRequestPermission =
    !isSubUser ||
    subUserRights({
      openBankingCard: true,
      openFinancialAccount: true,
      makeTransfers: true,
      viewAllCardsAndFinancialAccounts: true,
      mainAccess: true,
    });

  const authorizedUsersPermission =
    isMainBusiness ||
    subUserRights({
      mainAccess: true,
    });

  const setupMFAPermission =
    !isSubUser ||
    subUserRights({
      openBankingCard: true,
      openFinancialAccount: true,
      makeTransfers: true,
      viewAllCardsAndFinancialAccounts: true,
      mainAccess: true,
    });

  const changeLoginPermission =
    !isSubUser ||
    subUserRights({
      openBankingCard: true,
      openFinancialAccount: true,
      makeTransfers: true,
      viewAllCardsAndFinancialAccounts: true,
      mainAccess: true,
    });

  const loginManagementPermission =
    !isSubUser ||
    subUserRights({
      openBankingCard: true,
      openFinancialAccount: true,
      makeTransfers: true,
      viewAllCardsAndFinancialAccounts: true,
      mainAccess: true,
    });

  const loginHistoryPermission =
    !isSubUser ||
    subUserRights({
      openBankingCard: true,
      openFinancialAccount: true,
      makeTransfers: true,
      viewAllCardsAndFinancialAccounts: true,
      mainAccess: true,
    });

  const changePasswordPermission =
    !isSubUser ||
    subUserRights({
      openBankingCard: true,
      openFinancialAccount: true,
      makeTransfers: true,
      viewAllCardsAndFinancialAccounts: true,
      mainAccess: true,
    });

  const linkedAppsPermission =
    !isSubUser || subUserRights({ mainAccess: true });

  const atmLocatorPermission =
    !isSubUser ||
    subUserRights({
      openBankingCard: true,
      openFinancialAccount: true,
      makeTransfers: true,
      viewAllCardsAndFinancialAccounts: true,
      mainAccess: true,
    });

  const limitsAndConditionsPermission =
    !isSubUser ||
    subUserRights({
      openBankingCard: true,
      openFinancialAccount: true,
      makeTransfers: true,
      viewAllCardsAndFinancialAccounts: true,
      mainAccess: true,
    });

  const rewardConditionsPermission =
    //They told me to make access the same as for rewards
    isMainBusiness ||
    subUserRights({
      mainAccess: true,
    });

  const giftCardPermission = subUserRights({
    makeTransfers: true,
    mainAccess: true,
  }) || isConsumer;

  const canPayPayWithRewardPoints = !isConsumer;

  const manageAutomationsPermission =
    !isSubUser ||
    subUserRights({
      makeTransfers: true,
      mainAccess: true,
    });

  const canOpenCard =
    !isSubUser || subUserRights({ openBankingCard: true, mainAccess: true });

  const viewAllFaAndCards = subUserRights({
    viewAllCardsAndFinancialAccounts: true,
  });

  const homeBannerPermission =
    !isSubUser ||
    subUserRights({
      makeTransfers: true,
      mainAccess: true,
  });

  const directDepositPermission = isConsumer;

  const canOpenNewFa = useMemo(() => {
    return !isSubUser ||
      subUserRights({
        openFinancialAccount: true,
        mainAccess: true,
      });
  }, [isSubUser, isConsumer, unclosedFinancialAccounts]);

  const personalInformationPermission = !isMainBusiness;
  const companyInformationPermission = isMainBusiness;
  const connectedAccountPermission = !isSubUser || subUserRights({ mainAccess: true });
  const otherBanksPermision = isMainBusiness;

  return {
    cashflowPermission,
    cardTransactionsPermission,
    cardLimitsPermission,
    linkedAccountPermission,
    pinSetupPermission,
    reissueCardPermission,
    lockCardPermission,
    closeCardPermission,
    rewardsPermission,
    transferPermission,
    fromConnectedAccountPermission,
    betweenOwnAccountsPermission,
    toNeteviaCustomerPermission,
    managePayeePermission,
    payeeHistoryPermission,
    addPayeePermission,
    connectExternalAccountPermission,
    finAccountsPermission,
    languagePermission,
    notificationsPermission,
    notificationDevicesPermission,
    primaryAccountPermission,
    faqPermission,
    createRequestPermission,
    authorizedUsersPermission,
    setupMFAPermission,
    changeLoginPermission,
    loginManagementPermission,
    loginHistoryPermission,
    changePasswordPermission,
    linkedAppsPermission,
    atmLocatorPermission,
    rewardConditionsPermission,
    limitsAndConditionsPermission,
    giftCardPermission,
    manageAutomationsPermission,
    homeBannerPermission,
    canOpenCard,
    viewAllFaAndCards,
    canOpenNewFa,
    personalInformationPermission,
    companyInformationPermission,
    connectedAccountPermission,
    canPayPayWithRewardPoints,
    directDepositPermission,
    otherBanksPermision,
  };
};
