import { useFinancialAccounts } from 'api/hooksApi/useFinancialAccounts';
import {
  setFromPayee,
  setToFinancialAccount,
  setAmount,
  selectFromPayee,
  selectToFinancialAccount,
  selectAmount,
  selectFrequency,
  selectDateTransfer,
  setSelectFrequency,
  setSelectDateTransfer,
  selectTransferMemo,
  setTransferMemo
} from '../../model';
import { useDrawerStepperContext } from 'shared/ui/drawer-stepper';
import { TransitionAnimation } from 'shared/ui/animations';
import styles from './styles.module.scss';
import { useAppSelector, useAppDispatch } from 'shared/models';
import { findObjById, FREQUENCY_DATA } from 'entities/transfers';
import helper from 'services/helper';
import { MakeTransferForm } from './make-transfer-form';
import { FromConnectedAccountSchema } from '../../model';
import { useExternalPayees } from 'api/hooksApi/useExternalPayees';
import { Skeleton } from 'components/skeleton';
import { formatAccountName } from 'shared/lib/format';
import { useFormik } from 'formik';
import moment from 'moment';
import dayjs from 'dayjs';

export const MakeTransfer = () => {
  const dispatch = useAppDispatch();
  const { goNext } = useDrawerStepperContext();
  const { unclosedFinancialAccounts, financialAccountsIsLoading } =
    useFinancialAccounts();
  const { plaidPayees, isLoadingPayees } = useExternalPayees();

  const fromPayee = useAppSelector(selectFromPayee);
  const toFinancialAccount = useAppSelector(selectToFinancialAccount);
  const amount = useAppSelector(selectAmount);
  const frequency = useAppSelector(selectFrequency);
  const dateTransfer = useAppSelector(selectDateTransfer);
  const memo = useAppSelector(selectTransferMemo);
  const formik = useFormik({
    initialValues: {
      from: fromPayee?.id || '',
      to: toFinancialAccount?.id || '',
      amount: amount || '',
      frequency: frequency,
      dateTransfer: dayjs(dateTransfer),
      memo: memo,
    },
    validationSchema: FromConnectedAccountSchema,
    onSubmit: (form) => {
      dispatch(
        setFromPayee({
          value: findObjById(form.from, plaidPayees),
        })
      );
      dispatch(
        setToFinancialAccount({
          value: findObjById(form.to, unclosedFinancialAccounts),
        })
      );

      dispatch(setAmount({ value: form.amount }));

      dispatch(setSelectFrequency({ value: form.frequency }));

      dispatch(
        setSelectDateTransfer({
          value:
            dayjs.isDayjs(form?.dateTransfer) &&
            form.dateTransfer.isValid() &&
            !form.dateTransfer.isBefore(dayjs(), 'day')
              ? form.dateTransfer.toISOString()
              : moment().toISOString(),
        })
      );
      dispatch(setTransferMemo({ value: form.memo }));
      goNext('1');
    },
  });

  const optionsToAcc = unclosedFinancialAccounts.map((account) => ({
    id: account.financialAccountId,
    value: account.financialAccountId,
    content: (
      <div className={styles.dropDown}>
        <div>
          {formatAccountName(account.name)} (****
          {account.accountNumber.slice(-4)})
        </div>
        <div>$ {helper.moneyFormat(account?.availableCash?.value) || ''}</div>
      </div>
    ),
  }));

  const optionsFrequency = FREQUENCY_DATA.map((el) => ({
    id: el.title,
    value: el.title,
    content: el.title,
  }));

  return financialAccountsIsLoading || isLoadingPayees ? (
    <Skeleton
      width='100%'
      height='110px'
    />
  ) : (
    <TransitionAnimation>
      <MakeTransferForm
        formik={formik}
        optionsToAcc={optionsToAcc}
        payees={plaidPayees}
        optionsFrequency={optionsFrequency}
      />
    </TransitionAnimation>
  );
};
